import { FC } from 'react';
import { Menu, MenuItem, Link, ListItemText, LinkProps, Typography } from '@mui/material';
import { HELPER_LINKS } from 'src/features/lobby/components/Header/HelperMenu/helperLinks';

const getLinkProps = ({ url }: { url?: string } = {}): Partial<LinkProps> | undefined =>
  url
    ? {
        component: Link,
        target: '_blank',
        rel: 'noopener noreferrer',
        href: url,
        color: 'inherit',
        underline: 'none',
      }
    : undefined;

const StdHelperMenu: FC<{
  // eslint-disable-next-line @rushstack/no-new-null
  anchorElement: HTMLElement | null;
  onItemClick: () => void;
  onShowChameleonHelpBarClick: () => void;
}> = ({ anchorElement, onItemClick, onShowChameleonHelpBarClick }) => {
  return (
    <Menu
      anchorEl={anchorElement}
      open={!!anchorElement}
      onClose={onItemClick}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      slotProps={{
        paper: { sx: { minWidth: '220px' } },
      }}
    >
      {HELPER_LINKS.map(({ label, link, id }) => (
        <MenuItem key={`${label}-${link}`} data-testid={id} onClick={onItemClick}>
          <ListItemText
            primary={label}
            primaryTypographyProps={{
              title: link ? `Open ${label} in a new window` : undefined,
              ...getLinkProps({ url: link }),
            }}
          />
        </MenuItem>
      ))}
      <MenuItem onClick={onShowChameleonHelpBarClick}>
        <ListItemText primary="Search all resources" />
        <Typography variant="caption" color="text.disabled">
          Ctrl + K
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default StdHelperMenu;

import { FC, MouseEvent, useState } from 'react';
import { Button } from '@mui/material';
import useGetScreenSize from 'src/hooks/useGetScreenSize';
import { showChameleon } from 'src/services/telemetry/chameleon';
import XsHelperMenu from './XsHelperMenu';
import StdHelperMenu from './StdHelperMenu';

const HelperMenu: FC = () => {
  const { isXs } = useGetScreenSize();

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const openMenu = (e: MouseEvent<HTMLElement>): void => setAnchorElement(e.currentTarget);
  const closeMenu = (): void => setAnchorElement(null);

  const handleShowChameleonHelpBarClick = (): void => {
    // ref: https://developers.chameleon.io/#/apis/search?id=trigger-search-api
    showChameleon('helpbar');
    closeMenu();
  };

  return (
    <>
      <Button variant="outlined" size="small" sx={{ flexShrink: 0 }} onClick={openMenu}>
        Support
      </Button>
      {isXs ? (
        <XsHelperMenu
          anchorElement={anchorElement}
          onItemClick={closeMenu}
          onShowChameleonHelpBarClick={handleShowChameleonHelpBarClick}
        />
      ) : (
        <StdHelperMenu
          anchorElement={anchorElement}
          onItemClick={closeMenu}
          onShowChameleonHelpBarClick={handleShowChameleonHelpBarClick}
        />
      )}
    </>
  );
};

export default HelperMenu;

import { FC, useRef } from 'react';

import { Tabs } from '@mui/material';
import { useMutationObserver } from 'ahooks';
import pages from '../headerLinksList';
import RouterNavLink from './RouterNavLink';

const scrollNavItemIntoView = (navItemEl: HTMLElement): void => {
  navItemEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
};
const scrollToActiveNavItem = (containerEl: HTMLDivElement): void => {
  const activeNavItem = containerEl.querySelector<HTMLElement>('.active');
  if (!activeNavItem) return;
  scrollNavItemIntoView(activeNavItem);
};

// eslint-disable-next-line @rushstack/no-new-null
const getNavItemContainerEl = (tabsRefEl: HTMLDivElement | undefined | null): HTMLDivElement | undefined | null => {
  return tabsRefEl?.querySelector<HTMLDivElement>('[role="tablist"]');
};

const StdHeaderLinks: FC = () => {
  const tabsRef = useRef<HTMLDivElement>(null);
  useMutationObserver(
    (mutations) => {
      const containerEl = getNavItemContainerEl(tabsRef.current);
      if (!containerEl) return;

      mutations.forEach((mutation) => {
        // when lazy-load nav items are added
        if (containerEl.isSameNode(mutation.target) && mutation.type === 'childList') {
          scrollToActiveNavItem(containerEl);
          return;
        }

        // when switching between nav items
        if (
          containerEl.isSameNode(mutation.target.parentNode) &&
          mutation.type === 'attributes' &&
          // target is an Element
          // ref: https://developer.mozilla.org/en-US/docs/Web/API/MutationRecord/target#value
          (mutation.target as HTMLElement).classList.contains('active')
        ) {
          scrollNavItemIntoView(mutation.target as HTMLElement);
        }
      });
    },
    () => getNavItemContainerEl(tabsRef.current),
    {
      subtree: true,
      childList: true,
      attributes: true,
      attributeFilter: ['class'],
    }
  );

  return (
    <Tabs ref={tabsRef} value={false} variant="scrollable" scrollButtons="auto" role="navigation" aria-label="Main">
      {pages.map((page) => (
        <RouterNavLink
          key={page.linkName}
          label={page.linkName}
          to={page.linkUrl}
          data-gtm-header-link={page.linkName}
        />
      ))}
    </Tabs>
  );
};

export default StdHeaderLinks;
